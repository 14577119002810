import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row, Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
moment.locale('fr');
import Loading from '../../../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class ImportedShopsList extends React.Component {
  render() {
    let { shops, shownControls, forVision, fields } = this.props;

    return (
      <div className="as-imported-shops-list-overview">
        <div className="collapse show">
          <div className="panel__content">
            <div className="table-responsive">
              <table className="as-table dashboard__table-orders notStripped table table-striped">
                <thead>
                  {!fields && (
                    <tr>
                      <th>Commerce</th>
                      <th>Ville</th>
                      <th>Code postal</th>
                      {forVision && <th>Statut</th>}
                    </tr>
                  )}
                  {fields && (
                    <tr>
                      <th>Commerce</th>
                      {fields.map((f, idx) => (
                        <th key={idx}>{f.label}</th>
                      ))}
                    </tr>
                  )}
                </thead>
                <tbody>
                  {shops.map((s) => (
                    <tr key={s.id} style={{ cursor: 'pointer' }}>
                      <td style={{ width: '35%' }}>
                        <div className="as-dummy-wrapper">
                          <div className="as-img-wrap">
                            <div
                              className="as-img"
                              style={{
                                backgroundImage: !this.props.forVision
                                  ? `url(${process.env.REACT_APP_BOTTL_WEBROOT}/photos/inconnu/cave_generique.png)`
                                  : s.image_src.startsWith('http')
                                  ? `url(${s.image_src})`
                                  : `url(${process.env.REACT_APP_BOTTL_WEBROOT}/photos/inconnu/cave_generique.png)`,
                              }}
                            ></div>
                          </div>
                          <span className="catalog-item__link">
                            {s.name || 'Aucun nom renseigné'}
                          </span>
                        </div>
                      </td>
                      {!fields && (
                        <>
                          <td style={{ width: '15%' }}>
                            <span className="catalog-item__link">
                              {s.locality || 'Non renseigné'}
                            </span>
                          </td>
                          <td style={{ width: '15%' }}>
                            <span className="catalog-item__link">
                              {s.postal_code || 'Non renseigné'}
                            </span>
                          </td>
                        </>
                      )}
                      {fields &&
                        fields.map((f, idx) => (
                          <td style={{ width: f.width }} key={idx}>
                            {s[f.name] || 'Non renseigné'}
                          </td>
                        ))}
                      {forVision && (
                        <td style={{ width: '15%' }}>
                          <span className="catalog-item__link">
                            {s.bottlCommercialStatus}
                          </span>
                        </td>
                      )}
                      <td>
                        {(!shownControls || shownControls.includes('view')) && (
                          <button
                            type="button"
                            className="mb-0 btn btn-primary"
                            onClick={() => {
                              this.props.onShopOpen(s.id);
                            }}
                          >
                            Visualiser
                          </button>
                        )}
                        {(!shownControls ||
                          shownControls.includes('view_import')) && (
                          <button
                            type="button"
                            className="mb-0 btn btn-primary"
                            onClick={() => {
                              this.props.onImportOpen(s.fk_import);
                            }}
                          >
                            Voir l'import
                          </button>
                        )}
                        {(!shownControls || shownControls.includes('edit')) && (
                          <button
                            type="button"
                            className="mb-0 btn btn-info"
                            onClick={() => {
                              this.props.onShowUpdateForm(s.id);
                            }}
                          >
                            Modifier
                          </button>
                        )}
                        {(!shownControls ||
                          shownControls.includes('delete')) && (
                          <button
                            type="button"
                            className="mb-0 btn btn-danger"
                            onClick={() => {
                              this.props.onShopDelete(s.id);
                            }}
                          >
                            Supprimer
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImportedShopsList;

import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Loading from '../App/Loading';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import Map from './components/Campaign/Map';
import Panel from '../../shared/components/Panel';
import NumericFormat from 'react-number-format';
import { changeSidebarVisibility } from '../../redux/actions/sidebarActions';
import { Table } from 'reactstrap';
import { ResponsiveContainer, PieChart, Pie, Tooltip, Legend } from 'recharts';
import {
  LabelSeries,
  MarkSeries,
  VerticalGridLines,
  HorizontalGridLines,
  HorizontalBarSeries,
  XAxis,
  FlexibleWidthXYPlot,
  YAxis,
} from 'react-vis';

let colors = [
  '#ffa600',
  '#ff7c43',
  '#f95d6a',
  '#d45087',
  '#a05195',
  '#665191',
  '#2f4b7c',
  '#003f5c',
  '#ff393b',
  '#ffc500',
  '#c5f500',
  '#4a4b94',
  '#1e3d66',
  '#ff2e2f',
  '#002a47',
  '#e84557',
  '#ff944d',
];
colors = [...colors, ...colors];

class InsightsStudy extends React.Component {
  state = {
    loaded: false,
    loading: true,
    collection: [],
    study: [],
    participations_map: [],
    participations_count: 0,
    selected: [],
  };

  componentDidMount() {
    const {
      authentication: { user },
    } = this.props;
    let endpoint = `insights/studies/${this.props.match.params.id}`;

    const query = new URLSearchParams(window.location.search);
    if (query.has('token')) endpoint += '?token=' + query.get('token');

    if (!this.props.sidebar.collapse) {
      this.props.changeSidebarVisibility();
    }

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
      })
      .then((res) => {
        this.setState({
          loaded: true,
          loading: false,
          study: res.data.content.study,
          participations_map: res.data.content.participations_map,
          participations_count: res.data.content.participations_count,
          restitution: res.data.content.restitution,
        });
      });
  }

  render() {
    let { loaded, loading, study, restitution } = this.state;
    const {
      authentication: { user },
    } = this.props;

    if (restitution) {
      restitution = restitution.map(function (r) {
        if (!r.question_id) return r;

        if (!r.stats.counts) return r;

        const allFrequencies = [
          ...new Set(Object.values(r.stats.counts).map((c) => c['frequency'])),
        ];
        if (
          allFrequencies.length === 1 &&
          Object.values(r.stats.counts).length > 1
        ) {
          r.useAvgInsteadOfFrequencies = true;
          r.stats.newTotal = Object.values(r.stats.counts).reduce(
            (a, b) => a + b.avg,
            0
          );

          for (const k of Object.keys(r.stats.counts))
            r.stats.counts[k]['frequency'] =
              r.stats.counts[k]['avg'] / r.stats.newTotal;
        }
        return r;
      });
    }

    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {loaded && (
          <React.Fragment>
            <Row>
              <Col md={12}>
                <Link
                  to={`${
                    study.isSurvey
                      ? `/survey/studies?for_survey=1`
                      : `/insights/studies`
                  }`}
                  className="as-study-backlink"
                >
                  <ChevronLeftIcon /> Retour à la liste des études
                </Link>
                <h3 className="page-title as-study-title">
                  <strong>{study.restitutionTitle || study.name}</strong>
                </h3>
                <p className="as-study-intro">
                  {study.restitutionIntroduction}
                </p>
                <br />
              </Col>
              <div className="as-study-controls">
                {study.restitutionFile && (
                  <a
                    href={study.restitutionFile}
                    target="_blank"
                    className="btn btn-outline-primary"
                  >
                    Visualiser le rapport PDF
                  </a>
                )}
              </div>
            </Row>
            <Row style={{ alignItems: 'flex-end' }}>
              <Panel lg={4} xl={4} md={12} xs={12} panelClass="as-fix-height-x">
                <div className="dashboard__stat dashboard__stat--budget">
                  <div className="dashboard__stat-main">
                    <p className="dashboard__stat-main-title">
                      Nb. de participations
                    </p>
                    <p className="dashboard__stat-main-number">
                      {this.state.participations_count}
                    </p>
                    <hr />
                  </div>
                  <div className="dashboard__stat-main">
                    <p className="dashboard__stat-main-title">
                      Pourcentage des cavistes français
                    </p>
                    <p className="dashboard__stat-main-number">
                      <NumericFormat
                        displayType="text"
                        value={this.state.participations_map.caviste_percentage}
                        thousandsGroupStyle="thousand"
                        thousandSeparator=" "
                        decimalScale={2}
                        decimalSeparator=","
                        suffix={'%'}
                      />
                    </p>
                    <hr />
                  </div>
                  <div className="dashboard__stat-main">
                    <p className="dashboard__stat-main-title">
                      Pourcentage des cavistes indépendants
                    </p>
                    <p className="dashboard__stat-main-number">
                      <NumericFormat
                        displayType="text"
                        value={
                          this.state.participations_map
                            .caviste_independant_percentage
                        }
                        thousandsGroupStyle="thousand"
                        thousandSeparator=" "
                        decimalScale={2}
                        decimalSeparator=","
                        suffix={'%'}
                      />
                    </p>
                  </div>
                </div>
              </Panel>
              <Map items={this.state.participations_map.all} />
            </Row>
            <Row style={{ marginTop: '4rem' }}>
              <Col md={12}>
                <h3
                  className="page-title"
                  style={{ margin: 0, color: 'black', fontSize: '18pt' }}
                >
                  Synthèse des réponses
                </h3>
              </Col>
            </Row>

            <Row className="as-restitution">
              {restitution &&
                restitution.map((r, idx) => (
                  <Panel
                    key={idx}
                    lg={parseInt(r.w || 12)}
                    xl={parseInt(r.w || 12)}
                    md={parseInt(r.w || 12)}
                    sm={parseInt(r.w || 12)}
                    panelClass={`as-remove-controls ${
                      !r.question_id ? 'as-is-section' : 'as-is-question'
                    }`}
                    title={
                      !r.question_id
                        ? r.custom_label
                        : !r.custom_label
                        ? r.question.label
                        : r.custom_label
                    }
                  >
                    {r.question_id && r.display_type && (
                      <>
                        {/* TYPE - AVERAGE */}
                        {(r.display_type === 'average_integer' ||
                          r.display_type === 'average_decimal') && (
                          <div className="todo__description">
                            <div className="mobile-app-widget">
                              <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                                <p
                                  className="mobile-app-widget__total-stat"
                                  style={{ fontSize: '56px' }}
                                >
                                  <strong>
                                    <NumericFormat
                                      displayType="text"
                                      value={r.stats.avg}
                                      decimalScale={
                                        r.display_type === 'average_integer'
                                          ? 0
                                          : 1
                                      }
                                      thousandsGroupStyle="thousand"
                                      thousandSeparator=" "
                                      decimalSeparator=","
                                    />
                                  </strong>
                                </p>
                              </div>
                              <div className="mobile-app-widget__title">
                                <h5>En moyenne</h5>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* TYPE - HALF HALF */}
                        {r.display_type === 'halfhalf' && (
                          <Row>
                            <Col md={12} xl={6} lg={6} xs={12}>
                              <div className="mobile-app-widget">
                                <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                                  <p
                                    className="mobile-app-widget__total-stat"
                                    style={{ fontSize: '56px' }}
                                  >
                                    {(
                                      r.stats.frequencies_booleans[1] * 100
                                    ).toFixed(0)}
                                    %
                                  </p>
                                </div>
                                <div className="mobile-app-widget__title">
                                  <h5>Oui</h5>
                                </div>
                              </div>
                            </Col>

                            <Col md={12} xl={6} lg={6} xs={12}>
                              <div className="mobile-app-widget">
                                <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                                  <p
                                    className="mobile-app-widget__total-stat"
                                    style={{ fontSize: '56px' }}
                                  >
                                    {(
                                      r.stats.frequencies_booleans[0] * 100
                                    ).toFixed(0)}
                                    %
                                  </p>
                                </div>
                                <div className="mobile-app-widget__title">
                                  <h5>Non</h5>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}

                    {/* DISPLAY TYPE - TABLE AVERAGES */}
                    {r.display_type === 'table_averages' && (
                      <Table
                        responsive
                        striped
                        className="dashboard__table-orders notStripped"
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>Moyenne</th>
                          </tr>
                        </thead>

                        <tbody>
                          {Object.entries(r.stats).map((e, idx) => (
                            <tr key={`row-${idx}`}>
                              <td>
                                <strong>{e[0]}</strong>
                              </td>
                              <td>{e[1]['avg'].toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}

                    {/* DISPLAY TYPE - TABLE OCCURRENCES & FREQUENCIES */}
                    {r.display_type === 'table_occurrences' && (
                      <Table
                        responsive
                        striped
                        className="dashboard__table-orders notStripped"
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>Occurrences</th>
                            <th>% de répondants</th>
                          </tr>
                        </thead>

                        <tbody>
                          {(r.stats.override_choices.length > 0
                            ? r.stats.override_choices
                            : r.question.choices
                          )
                            .sort(
                              (a, b) =>
                                (r.stats.frequencies[b] || 0) -
                                (r.stats.frequencies[a] || 0)
                            )
                            .slice(
                              0,
                              r.stats.override_choices
                                ? 10
                                : r.question.choices.length
                            )
                            .map((c, idx) => (
                              <tr key={`row-${idx}`}>
                                <td>
                                  <strong>{c}</strong>
                                </td>
                                <td>{r.stats.occurrences[c] || 0}</td>
                                <td>
                                  {(
                                    (r.stats.frequencies[c] || 0) * 100
                                  ).toFixed(2)}
                                  %
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    )}

                    {/* DISPLAY TYPE - GRAPHIQUE BARS */}
                    {r.display_type === 'bars_chart' && (
                      <ResponsiveContainer className="dashboard__chart-pie dashboard__chart-pie--big">
                        <FlexibleWidthXYPlot
                          yType="ordinal"
                          height={300}
                          // width={window.innerWidth * 0.85}

                          // 6.8px autorisé en largeur pour chaque caractère du plus grand libellé
                          // pour éviter d'être tronqué
                          // Valeur auparavant: 140px (une valeur fixe c'est pas génial esthétiquement)
                          margin={{
                            left:
                              Math.max(
                                ...Object.keys(r.stats.frequencies_texts).map(
                                  (l) => l.length
                                )
                              ) * 6.8,
                          }}
                          stackBy="x"
                        >
                          <VerticalGridLines />
                          <HorizontalGridLines />
                          <XAxis
                            tickFormat={function tickFormat(d) {
                              return !r.stats.force_integers ? `${d}%` : d;
                            }}
                          />
                          <YAxis
                            style={{
                              text: {
                                stroke: 'none',
                                fill: '#6b6b76',
                                fontWeight: 600,
                              },
                            }}
                          />
                          <HorizontalBarSeries
                            data={[...Object.entries(r.stats.frequencies_texts)]
                              .sort((ea, eb) => (ea[1] > eb[1] ? 1 : -1))
                              .map((e) => ({
                                y: e[0],
                                x: r.stats.force_integers ? e[1] : e[1] * 100,
                                // x: e[1].toFixed(2),
                              }))}
                            colorType="literal"
                            color="#0088a3"
                          />
                        </FlexibleWidthXYPlot>
                      </ResponsiveContainer>
                    )}

                    {/* DISPLAY TYPE - PIE CHART */}
                    {r.display_type === 'pie_chart' && (
                      <div dir="ltr">
                        <ResponsiveContainer className="dashboard__chart-pie dashboard__chart-pie--insight">
                          <PieChart className="dashboard__chart-pie-container">
                            <Pie
                              isAnimationActive={false}
                              data={Object.entries(r.stats.counts).map(
                                function (e, idx) {
                                  return {
                                    fill: colors[idx],
                                    color: colors[idx],
                                    value: parseFloat(
                                      (e[1]['frequency'] * 100).toFixed(2)
                                    ),
                                    // total: r.useAvgInsteadOfFrequencies
                                    //   ? e[1]['avg']
                                    //   : e[1]['count'],
                                    name: e[0],
                                  };
                                }
                              )}
                              dataKey="value"
                              nameKey="name"
                              cy={200}
                              innerRadius={100}
                              outerRadius={150}
                              label={({
                                cx,
                                cy,
                                midAngle,
                                innerRadius,
                                outerRadius,
                                value,
                              }) => {
                                const RADIAN = Math.PI / 180;
                                const radius =
                                  25 +
                                  innerRadius +
                                  (outerRadius - innerRadius);
                                const x =
                                  cx + radius * Math.cos(-midAngle * RADIAN);
                                const y =
                                  cy + radius * Math.sin(-midAngle * RADIAN);
                                return (
                                  <text
                                    x={x}
                                    y={y}
                                    fill="#cccccc"
                                    textAnchor={x > cx ? 'start' : 'end'}
                                    dominantBaseline="central"
                                  >
                                    {value}%
                                  </text>
                                );
                              }}
                            />
                            <Tooltip
                              content={({ payload }) => (
                                <React.Fragment>
                                  {payload.map((item, index) => {
                                    return (
                                      <div
                                        key={`tooltip-${index}`}
                                        style={{
                                          opacity: 0.9,
                                          backgroundColor: '#fff',
                                          padding: '.5rem 1rem',
                                          border: '1px solid #dbdbdd',
                                          boxShadow:
                                            '0 10px 30px 1px rgb(0 0 0 / 6%)',
                                          borderRadius: '0.25rem',
                                        }}
                                      >{`${item.name}: ${item.value}%`}</div>
                                    );
                                  })}
                                </React.Fragment>
                              )}
                            />
                            <Legend
                              layout="vertical"
                              verticalAlign="bottom"
                              wrapperStyle={{
                                width: 320,
                                lineHeight: '24px',
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                              }}
                              content={({ payload }) => (
                                <ul className="dashboard__chart-legend">
                                  {payload.map((entry, index) => (
                                    <li key={`item-${index}`}>
                                      <span
                                        style={{ backgroundColor: entry.color }}
                                      />
                                      {entry.value}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            />
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    )}

                    {/* COMMENT */}
                    {r.comment && (
                      <div className="as-comment">
                        <p>{r.comment}</p>
                      </div>
                    )}
                  </Panel>
                ))}
            </Row>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeSidebarVisibility: (state) => dispatch(changeSidebarVisibility(state)),
});
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps, mapDispatchToProps)(InsightsStudy);
